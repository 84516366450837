// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { IntegrityError } from '../../../errors/IntegrityError';
import { parser } from './client/runtime';
import { deserializeCompletedPartList, emptyArrayGuard, map, } from './client/utils';
import { isEqual } from './client/utils/integrityHelpers';
export function validateMultipartUploadXML(input, xml) {
    if (!input.Parts) {
        throw new IntegrityError();
    }
    const parsedXML = parser.parse(xml);
    const mappedCompletedMultipartUpload = map(parsedXML, {
        Parts: [
            'Part',
            value => emptyArrayGuard(value, deserializeCompletedPartList),
        ],
    });
    if (!isEqual(input, mappedCompletedMultipartUpload)) {
        throw new IntegrityError();
    }
}
