// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { defaultStorage } from '@aws-amplify/core';
import { uploadData as uploadDataInternal } from './internal/uploadData';
export function uploadData(input) {
    return uploadDataInternal({
        ...input,
        options: {
            ...input?.options,
            // This option enables caching in-progress multipart uploads.
            // It's ONLY needed for client-side API.
            resumableUploadsCache: defaultStorage,
        },
    });
}
