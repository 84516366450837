export { CONTROL_MSG, ConnectionState } from './PubSub';
export var GraphQLAuthError;
(function (GraphQLAuthError) {
    GraphQLAuthError["NO_API_KEY"] = "No api-key configured";
    GraphQLAuthError["NO_CURRENT_USER"] = "No current user";
    GraphQLAuthError["NO_CREDENTIALS"] = "No credentials";
    GraphQLAuthError["NO_FEDERATED_JWT"] = "No federated jwt";
    GraphQLAuthError["NO_AUTH_TOKEN"] = "No auth token specified";
})(GraphQLAuthError || (GraphQLAuthError = {}));
export const __amplify = Symbol('amplify');
export const __authMode = Symbol('authMode');
export const __authToken = Symbol('authToken');
export const __apiKey = Symbol('apiKey');
export const __headers = Symbol('headers');
export const __endpoint = Symbol('endpoint');
export function getInternals(client) {
    const c = client;
    return {
        amplify: c[__amplify],
        apiKey: c[__apiKey],
        authMode: c[__authMode],
        authToken: c[__authToken],
        endpoint: c[__endpoint],
        headers: c[__headers],
    };
}
