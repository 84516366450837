import {createApp} from 'vue'
import App from './App.vue'
import router from './router'

import {
    IonicVue,
} from '@ionic/vue';
// @ts-ignore
// import i18n from "./i18n";
import VueKonva from "vue-konva";

import VueSplide from "@splidejs/vue-splide";
import { Amplify } from "aws-amplify";

// @ts-ignore
import config from "./amplifyconfiguration.json";

Amplify.configure(config);
import { DataStore } from "aws-amplify/datastore";

DataStore.configure({
  syncPageSize: 100,
});

import "./registerServiceWorker.js";
// eslint-disable-next-line
// @ts-ignore
// import { default as nl } from "./locales/nl.json";
// // eslint-disable-next-line
// // @ts-ignore
// import { default as en } from "./locales/en.json";
// // eslint-disable-next-line
// // @ts-ignore
// import { default as de } from "./locales/de.json";
// // eslint-disable-next-line
// // @ts-ignore
// import { default as cn } from "./locales/cn.json";
// // eslint-disable-next-line
// // @ts-ignore
// import { default as lt } from "./locales/lt.json";
// // eslint-disable-next-line
// // @ts-ignore
// import { default as pt } from "./locales/pt.json";
import en from './locales/en.json'
import nl from './locales/nl.json'
// import { default as messages } from "./locales/en.json";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
// eslint-disable-next-line
// @ts-ignore
import VueZoomer from "vue-zoomer";

// import "@aws-amplify/ui-vue/styles.css";
/* Theme variables */
import "./theme/variables.css";
import "./theme/quasar.prod.css";
// import 'quasar/dist/quasar.prod.css';

import "@/fortawesome/fontawesome-pro/css/all.css";
// import i18n from './i18n';

// const i18n = createI18n({
//   legacy: false,
//   global: true,
//   locale: nl,
//   fallbackLocale: en, // set fallback locale
//   messages: {
//     en: en,
//     cn: cn,
//     de: de,
//     pt: pt,
//     lt: lt,
//     nl: nl,
//   },
// });
import { createI18n } from 'vue-i18n'
const i18n = createI18n({
    locale: 'nl',
    messages: {
        en: en,
        nl: nl,
    }
});

import { defineCustomElements } from '@ionic/pwa-elements/loader';
defineCustomElements(window);

import { SessionStorage, LocalStorage, Notify, Quasar } from "quasar";
import iconSet from "quasar/icon-set/fontawesome-v6";
// eslint-disable-next-line
// @ts-ignore
const app = createApp(App)
  .use(Quasar, {
    config: {
      brand: {
        primary: "#102642",
        secondary: "#283c55",
        tertiary: "#ffffff",
        warning: "#27528b",
        accent: "#afafaf",
        dark: "#1d1d1d",
        positive: "#21BA45",
        negative: "#eb445a",
        info: "#3399ff",
      },
      notify: {
        /* look at QuasarConfOptions from the API card */
      },
    },
    plugins: {
      LocalStorage,
      SessionStorage,
      Notify,
    },
    framework: { cssAddon: true },
    extras: ["fontawesome-v6"],
    iconSet: iconSet,
  })
  .use(i18n)
  .use(IonicVue)
  .use(router)
  .use(VueZoomer)
  .use(VueKonva)
  .use(VueSplide);

router.isReady().then(() => {
  app.mount("#app");
});
