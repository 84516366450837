// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { parseMetadata, } from '@aws-amplify/core/internals/aws-client-utils';
import { AmplifyUrl, AmplifyUrlSearchParams, } from '@aws-amplify/core/internals/utils';
import { composeServiceApi } from '@aws-amplify/core/internals/aws-client-utils/composers';
import { assignStringVariables, buildStorageServiceError, map, s3TransferHandler, serializePathnameObjectKey, validateS3RequiredParameter, } from '../utils';
import { validateObjectUrl } from '../../validateObjectUrl';
import { defaultConfig, parseXmlError } from './base';
const uploadPartSerializer = async (input, endpoint) => {
    const headers = {
        ...assignStringVariables({
            'x-amz-checksum-crc32': input.ChecksumCRC32,
            'content-md5': input.ContentMD5,
            'x-amz-expected-bucket-owner': input.ExpectedBucketOwner,
        }),
        'content-type': 'application/octet-stream',
    };
    const url = new AmplifyUrl(endpoint.url.toString());
    validateS3RequiredParameter(!!input.Key, 'Key');
    url.pathname = serializePathnameObjectKey(url, input.Key);
    validateS3RequiredParameter(!!input.PartNumber, 'PartNumber');
    validateS3RequiredParameter(!!input.UploadId, 'UploadId');
    url.search = new AmplifyUrlSearchParams({
        partNumber: input.PartNumber + '',
        uploadId: input.UploadId,
    }).toString();
    validateObjectUrl({
        bucketName: input.Bucket,
        key: input.Key,
        objectURL: url,
    });
    return {
        method: 'PUT',
        headers,
        url,
        body: input.Body,
    };
};
const uploadPartDeserializer = async (response) => {
    if (response.statusCode >= 300) {
        const error = (await parseXmlError(response));
        throw buildStorageServiceError(error, response.statusCode);
    }
    else {
        return {
            ...map(response.headers, {
                ETag: 'etag',
            }),
            $metadata: parseMetadata(response),
        };
    }
};
export const uploadPart = composeServiceApi(s3TransferHandler, uploadPartSerializer, uploadPartDeserializer, { ...defaultConfig, responseType: 'text' });
