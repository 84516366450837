<template>
  <ion-app>
    <ion-menu
      style="--background: var(--ion-color-tertiary)"
      :backdrop="true"
      ref="menu"
      :visible="true"
      side="end"
      @ionWillOpen="async () => await getOrders()"
      @ionDidClose="
        () => {
          closeAccordion();
          scrollMenuContent();
          scrollRef = false;
        }
      "
      content-id="main"
      menu-id="FtrSideMenu"
    >
      <ion-toolbar
        mode="ios"
        color="secondary"
        style="
          position: absolute;
          top: 0;
          padding-top: var(--ion-safe-area-top);
        "
      >
        <ion-buttons mode="ios" slot="start">
          <ion-button @click="() => menuController.close()">Close</ion-button>
        </ion-buttons>
        <ion-title mode="ios" v-if="user"
          >{{ "Hallo " + user?.given_name }}!
        </ion-title>
        <ion-buttons
          mode="ios"
          v-if="logged_in_user"
          slot="end"
          style="padding-right: 20px"
        >
          <ion-button
            v-if="logged_in_user['custom:isAdmin']?.includes('true')"
            @click="
              () => {
                router.push('/admin');
                menuController.close();
              }
            "
            fill="solid"
            color="primary"
          >
            <i
              style="font-size: 26px; color: rgba(255, 255, 255, 0.9)"
              class="fa-light fa-tools"
            ></i>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-header
        mode="ios"
        collapse="fade"
        :style="[
          scrollRef !== false ? 'z-index:600;opacity:1' : 'z-index:0;opacity:0',
        ]"
        style="
          background: var(--ion-color-tertiary);
          border: none;
          top: 50px;
          transition: opacity 0.25s linear;
        "
      >
        <ion-toolbar
          mode="ios"
          style="
            --background: var(--ion-color-tertiary);
            --border-style: hidden;
            padding-top: 0;
          "
        >
          <ion-buttons mode="ios" style="width: 86%; margin-left: 7%">
            <ion-searchbar
              :debounce="1000"
              mode="ios"
              style="
                padding-bottom: 0;
                margin-top: 30px;
                background: var(--ion-color-tertiary);
              "
              @ionInput="
                store.methods.searchSubmittedOrders($event.target.value)
              "
            ></ion-searchbar>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-list
        mode="ios"
        style="
          z-index: 50;
          max-height: 100vh;
          height: 100%;
          background: var(--ion-color-tertiary);
        "
      >
        <ion-accordion-group
          @ionChange="async () => await getOrders()"
          ref="accordionGroup"
          expand="inset"
          style="z-index: 50; width: 90%; margin-left: 5%; margin-right: 5%"
        >
          <ion-accordion
            class="order"
            style="max-height: 95vh; background: var(--ion-color-tertiary)"
            v-if="user"
            value="orders"
          >
            <ion-item
              mode="ios"
              :style="[
                scrollRef === false
                  ? 'z-index:700;opacity:1'
                  : 'z-index:0:opacity:0',
              ]"
              style="
                --background: var(--ion-color-tertiary);
                transition: opacity 0.25s linear;
              "
              ref="orderHistoryContainer"
              slot="header"
              button
            >
              <ion-avatar
                slot="start"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <i
                  class="fa-light fa-clipboard-list"
                  style="font-size: 20px"
                ></i>
              </ion-avatar>
              <ion-label>Order menu</ion-label>
            </ion-item>

            <ion-list
              slot="content"
              style="min-height: 90vh; background: var(--ion-color-tertiary)"
            >
              <ion-content
                id="content"
                mode="ios"
                ref="scroller"
                color="tertiary"
                :scroll-events="true"
                @ionScroll="onScrollFirst"
                style="min-height: 90vh; height: 100%"
              >
                <ion-refresher
                  mode="ios"
                  style="background: transparent"
                  slot="fixed"
                  @ionRefresh="doRefresh($event)"
                >
                  <ion-refresher-content
                    mode="ios"
                    style="background: transparent"
                  >
                  </ion-refresher-content>
                </ion-refresher>

                <ion-header
                  style="display: block"
                  mode="ios"
                  collapse="condense"
                >
                  <ion-toolbar
                    mode="ios"
                    style="--background: var(--ion-color-tertiary)"
                  >
                    <ion-buttons
                      mode="ios"
                      style="width: 100%; padding-top: 8px"
                    >
                      <ion-segment
                        mode="ios"
                        :value="orderListType"
                        style="width: 100%"
                      >
                        <ion-segment-button
                          mode="ios"
                          layout="icon-start"
                          value="history"
                          @click="setOrderListType('history')"
                        >
                          <i
                            class="fal fa-history"
                            style="margin-right: 5px"
                          ></i>
                          <ion-label>Order historie</ion-label>
                        </ion-segment-button>

                        <ion-segment-button
                          mode="ios"
                          layout="icon-start"
                          value="modifications"
                          @click="setOrderListType('modifications')"
                        >
                          <i class="fal fa-tools" style="margin-right: 5px"></i>
                          <ion-label>Correcties</ion-label>
                        </ion-segment-button>

                        <ion-segment-button
                          mode="ios"
                          v-if="store.state.archivedOrders.length > 0"
                          layout="icon-start"
                          style="padding-left: 5px; padding-right: 5px"
                          value="archived"
                          @click="setOrderListType('archived')"
                        >
                          <i
                            class="fal fa-archive"
                            style="margin-right: 5px"
                          ></i>
                          <ion-label>Opgeslagen</ion-label>
                          <ion-badge
                            color="danger"
                            style="
                              margin-inline-start: 5px;
                              color: white;
                              display: flex;
                              justify-content: center;
                            "
                          >
                            {{ store.state.archivedOrders.length }}
                          </ion-badge>
                        </ion-segment-button>
                      </ion-segment>
                    </ion-buttons>
                  </ion-toolbar>
                  <ion-toolbar
                    mode="ios"
                    style="--background: var(--ion-color-tertiary)"
                  >
                    <ion-buttons mode="ios">
                      <ion-searchbar
                        :debounce="1000"
                        mode="ios"
                        style="
                          margin-top: 10px;
                          background: var(--ion-color-tertiary);
                        "
                        @ionInput="
                          orderListType === 'modifications'
                            ? store.methods.searchModificationOrders(
                                $event.target.value
                              )
                            : store.methods.searchSubmittedOrders(
                                $event.target.value
                              )
                        "
                      ></ion-searchbar>
                    </ion-buttons>
                  </ion-toolbar>
                </ion-header>

                <ion-list
                  v-if="loading === false"
                  style="--background: var(--ion-color-tertiary)"
                >
                  <ion-card v-for="item in 5" :key="item">
                    <ion-card-header style="padding: 5px 20px 16px">
                      <q-skeleton
                        animation="fade"
                        style="position: relative; left: 95%; top: 5px"
                        height="24px"
                        width="24px"
                        type="rect"
                      >
                      </q-skeleton>

                      <ion-row style="margin-top: 10px">
                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-subtitle>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              width="70%"
                            ></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-title>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              style="top: -2px"
                              width="60%"
                            ></q-skeleton>
                          </ion-card-title>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-subtitle>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              width="80%"
                            ></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-title>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              style="top: -2px"
                              width="80%"
                            ></q-skeleton>
                          </ion-card-title>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-subtitle>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              width="60%"
                            ></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-subtitle>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              width="70%"
                            ></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-subtitle>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              width="40%"
                            ></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-subtitle>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              width="60%"
                            ></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>
                      </ion-row>

                      <q-separator />
                    </ion-card-header>

                    <ion-card-content
                      style="
                        margin-bottom: 15px;
                        display: flex;
                        justify-content: space-around;
                      "
                    >
                      <q-skeleton animation="fade" type="QBtn" height="40px">
                      </q-skeleton>

                      <q-skeleton animation="fade" type="QBtn" height="40px">
                      </q-skeleton>
                    </ion-card-content>
                  </ion-card>
                </ion-list>

                <ion-list
                  v-else-if="loading === true && orderListType === 'history'"
                  style="background: var(--ion-color-tertiary)"
                >
                  <ion-card
                    v-for="order in store.state.submittedOrders"
                    :key="order.id"
                  >
                    <!--                    <img alt="Silhouette of mountains" :src='"https://care-file-storage75837-staging.s3.eu-west-1.amazonaws.com/public/" + order.filename' />-->
                    <ion-card-header>
                      <!--                      <ion-chip v-if="order.status.includes('submit')" color="success"-->
                      <!--                                style="position: relative;left:90%;&#45;&#45;color:var(&#45;&#45;ion-color-success);">-->
                      <!--                        <i style="font-size: 24px" class="fas fa-clipboard-check"></i>-->
                      <!--                      </ion-chip>-->
                      <!--                      <ion-chip v-else color="danger"-->
                      <!--                                style="position: relative;left:85%;&#45;&#45;color:var(&#45;&#45;ion-color-success);">-->
                      <!--                        <i class="fa-light fa-clipboard-list-check"></i>-->
                      <!--                      </ion-chip>-->

                      <ion-row>
                        <ion-col size="5" style="padding: 0; margin: 0">
                          <ion-card-subtitle
                            style="width: 100%; font-weight: 800"
                          >
                            Leestnummer:
                          </ion-card-subtitle>
                        </ion-col>
                        <ion-col size="7" style="padding: 0; margin: 0">
                          <ion-card-title
                            style="
                              font-size: 20px;
                              width: 100%;
                              color: var(--ion-color-primary);
                            "
                          >
                            {{ order.orderIdOne }}
                          </ion-card-title>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="5" style="padding: 0; margin: 0">
                          <ion-card-subtitle
                            style="width: 100%; font-weight: 800"
                          >
                            Klantreferentie:
                          </ion-card-subtitle>
                        </ion-col>
                        <ion-col size="7" style="padding: 0; margin: 0">
                          <ion-card-title
                            style="
                              font-size: 20px;
                              width: 100%;
                              color: var(--ion-color-primary);
                            "
                          >
                            {{ order.orderIdTwo }}
                          </ion-card-title>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="5" style="padding: 0; margin: 0">
                          <ion-card-subtitle
                            style="width: 100%; font-weight: 800"
                          >
                            Ingediend op:
                          </ion-card-subtitle>
                        </ion-col>
                        <ion-col size="7" style="padding: 0; margin: 0">
                          <ion-card-subtitle style="width: 100%">
                            {{ toDate(parseInt(order.submittedAt)) }}
                          </ion-card-subtitle>
                        </ion-col>
                      </ion-row>

                      <ion-row>
                        <ion-col size="5" style="padding: 0; margin: 0">
                          <ion-card-subtitle
                            style="width: 100%; font-weight: 800"
                          >
                            Adviseur:
                          </ion-card-subtitle>
                        </ion-col>
                        <ion-col size="7" style="padding: 0; margin: 0">
                          <ion-card-subtitle style="width: 100%">
                            {{ order.userName }}
                          </ion-card-subtitle>
                        </ion-col>
                      </ion-row>

                      <ion-row>
                        <ion-col size="5" style="padding: 0; margin: 0">
                          <ion-card-subtitle
                            style="width: 100%; font-weight: 800"
                          >
                            Status:
                          </ion-card-subtitle>
                        </ion-col>
                        <ion-col size="7" style="padding: 0; margin: 0">
                          <ion-card-subtitle style="width: 100%">
                            {{ order.status }}
                          </ion-card-subtitle>
                        </ion-col>
                      </ion-row>
                    </ion-card-header>

                    <!--                    <ion-card-content-->
                    <!--                        style="margin-bottom:15px;display: flex;justify-content: space-around">-->

                    <template v-if="(editButton = true)">
                      <ion-button
                        fill="clear"
                        @click="navigateTo(order, '/order/edit_')"
                      >
                        <ion-label
                          ><i
                            class="fa-light fa-pen-to-square"
                            style="margin-right: 5px"
                          ></i
                          >Edit
                        </ion-label>
                      </ion-button>
                    </template>
                    <template v-else>
                      <ion-button fill="clear" @click="reorder(order.id)">
                        <ion-label
                          ><i
                            class="fa-light fa-clone"
                            style="margin-right: 5px"
                          ></i
                          >Nabestelling
                        </ion-label>
                      </ion-button>
                    </template>

                    <ion-button
                      fill="clear"
                      @click="navigateTo(order, '/print-form/')"
                    >
                      <ion-label
                        ><i
                          class="fa-light fa-memo-pad"
                          style="margin-right: 5px"
                        ></i
                        >Formulier
                      </ion-label>
                    </ion-button>

                    <!--                    </ion-card-content>-->
                  </ion-card>
                </ion-list>

                <ion-list
                  v-else-if="
                    loading === true && orderListType === 'modifications'
                  "
                  style="--background: var(--ion-color-tertiary); width: 100%"
                >
                  <ion-card
                    v-for="order in store.state.modificationOrders"
                    :key="order.id"
                  >
                    <ion-card-header>
                      <ion-row>
                        <ion-col size="5" style="padding: 0; margin: 0">
                          <ion-card-subtitle
                            style="width: 100%; font-weight: 800"
                          >
                            Leestnummer:
                          </ion-card-subtitle>
                        </ion-col>
                        <ion-col size="7" style="padding: 0; margin: 0">
                          <ion-card-title
                            style="
                              font-size: 20px;
                              width: 100%;
                              color: var(--ion-color-primary);
                            "
                          >
                            {{ order.orderIdOne }}
                          </ion-card-title>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="5" style="padding: 0; margin: 0">
                          <ion-card-subtitle
                            style="width: 100%; font-weight: 800"
                          >
                            Klantreferentie:
                          </ion-card-subtitle>
                        </ion-col>
                        <ion-col size="7" style="padding: 0; margin: 0">
                          <ion-card-title
                            style="
                              font-size: 20px;
                              width: 100%;
                              color: var(--ion-color-primary);
                            "
                          >
                            {{ order.orderIdTwo }}
                          </ion-card-title>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="5" style="padding: 0; margin: 0">
                          <ion-card-subtitle
                            style="width: 100%; font-weight: 800"
                          >
                            Ingediend op:
                          </ion-card-subtitle>
                        </ion-col>
                        <ion-col size="7" style="padding: 0; margin: 0">
                          <ion-card-subtitle style="width: 100%">
                            {{ toDate(parseInt(order.submittedAt)) }}
                          </ion-card-subtitle>
                        </ion-col>
                      </ion-row>

                      <ion-row>
                        <ion-col size="5" style="padding: 0; margin: 0">
                          <ion-card-subtitle
                            style="width: 100%; font-weight: 800"
                          >
                            Adviseur:
                          </ion-card-subtitle>
                        </ion-col>
                        <ion-col size="7" style="padding: 0; margin: 0">
                          <ion-card-subtitle style="width: 100%">
                            {{ order.userName }}
                          </ion-card-subtitle>
                        </ion-col>
                      </ion-row>
                    </ion-card-header>

                    <!--                            <ion-button color="danger" fill="solid" @click="(() => {-->
                    <!--                                                    menuController.close('FtrSideMenu').then(() => {-->
                    <!--                                                     router.push('/modification-form/' + order.id)-->
                    <!--                                                    })})">-->
                    <!--                              <ion-label style="color:white"><i-->
                    <!--                                  class="fa-light fa-trash-can"-->
                    <!--                                  style="margin-right:5px"></i>-->
                    <!--                                Verwijder-->
                    <!--                              </ion-label>-->
                    <!--                            </ion-button>-->

                    <ion-button
                      fill="clear"
                      @click="navigateTo(order, '/print-modifications-form/')"
                    >
                      <ion-label
                        ><i
                          class="fa-light fa-memo-pad"
                          style="margin-right: 5px"
                        ></i
                        >Formulier
                      </ion-label>
                    </ion-button>
                  </ion-card>
                </ion-list>
                <!--                <ion-list v-else-if="store.state.submittedOrders?.length === 0 && orderListType === 'history'"-->
                <!--                          style="&#45;&#45;background:var(&#45;&#45;ion-color-tertiary);width:100%;">-->
                <!--                  <ion-label style="margin: 20px"><h1>Geen historie</h1></ion-label>-->
                <!--                </ion-list>-->
                <ion-list
                  v-else-if="
                    loading === true &&
                    store.state.archivedOrders.length > 0 &&
                    orderListType === 'archived'
                  "
                  style="
                    --background: var(--ion-color-tertiary);
                    width: 100%;
                    height: 100%;
                  "
                >
                  <ion-card
                    v-for="order in store.state.archivedOrders"
                    :key="order.id"
                  >
                    <ion-card-header>
                      <ion-chip>
                        {{ orderListType }}
                      </ion-chip>
                      <ion-card-title
                        style="width: 100%; color: var(--ion-color-primary)"
                      >
                        {{ order.orderIdOne + " " + order.orderIdTwo }}
                      </ion-card-title>
                      <ion-card-subtitle style="width: 100%">
                        {{ order.updatedAt }}
                      </ion-card-subtitle>
                      <q-separator style="margin-bottom: 10px" />
                      <ion-card-subtitle style="width: 100%">
                        {{ "Status: " + order.status }}
                      </ion-card-subtitle>
                      <q-separator style="margin-top: 10px" />
                    </ion-card-header>
                    <ion-card-content style="margin-bottom: 15px">
                      <ion-grid>
                        <ion-row>
                          <ion-col size="6">
                            <ion-button
                              color="danger"
                              fill="solid"
                              @click="navigateTo(order, '/print-form/')"
                            >
                              <ion-label style="color: white"
                                ><i
                                  class="fa-light fa-trash-can"
                                  style="margin-right: 5px"
                                ></i>
                                Verwijder
                              </ion-label>
                            </ion-button>
                          </ion-col>
                          <ion-col
                            size="6"
                            style="display: flex; justify-content: center"
                          >
                            <ion-button
                              color="warning"
                              fill="solid"
                              style="--color: white"
                              @click="navigateTo(order, '/order/order_')"
                            >
                              <ion-label style="color: white"
                                ><i
                                  class="fa-light fa-memo-circle-check"
                                  style="margin-right: 5px"
                                ></i
                                >Verder
                              </ion-label>
                            </ion-button>
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                    </ion-card-content>
                  </ion-card>
                </ion-list>
              </ion-content>
            </ion-list>
          </ion-accordion>

          <ion-accordion value="colors">
            <ion-item slot="header" button>
              <ion-avatar
                slot="start"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <i class="fa-light fa-circle-user" style="font-size: 20px"></i>
              </ion-avatar>
              <ion-label>Account</ion-label>
              <!--              <ion-label><p>{{ user.attributes.given_name + ' ' + user.attributes.family_name }}</p></ion-label>-->
            </ion-item>

            <ion-list
              slot="content"
              style="
                width: 100%;
                --inner-padding-end: 0;
                --background: var(--ion-color-tertiary);
                --padding-inline-start: 0;
                --padding-inline-end: 0;
                --padding-start: 0;
                --padding: 0;
                --border-width: 0.15px 0px 0px 0px;
                --border-style: solid;
                --border-color: rgba(55, 55, 55, 0.2);
                --padding-top: 10px;
              "
            >
              <authenticator :hide-sign-up="true" :login-mechanisms="['email']">
                <template v-slot="{ signOut }">
                  <div
                    style="
                      width: 100%;
                      padding-top: 10px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <ion-button
                      v-if="logged_in_user"
                      capitalize
                      color="danger"
                      size="lg"
                      @click="signOut"
                    >
                      <ion-text style="font-size: 16px"
                        ><i
                          class="fa-light fa-sign-out"
                          style="font-size: 16px; margin-right: 5px"
                        ></i
                        >|
                      </ion-text>
                      <ion-text style="font-size: 16px; margin-left: 5px"
                        >Uitloggen
                      </ion-text>
                    </ion-button>
                  </div>
                </template>
              </authenticator>
            </ion-list>
          </ion-accordion>

          <ion-accordion
            style="border-bottom: solid 0.15px rgba(25, 25, 25, 0.25)"
            value="help"
          >
            <ion-item slot="header" button>
              <ion-avatar
                slot="start"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <i
                  class="fa-light fa-comments-question-check"
                  style="font-size: 20px"
                ></i>
              </ion-avatar>
              <ion-label> Help</ion-label>
            </ion-item>

            <ion-list
              slot="content"
              style="width: 80%; margin-left: 10%; margin-bottom: 10px"
            >
              <ion-item button lines="full">
                <ion-avatar
                  slot="start"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <i
                    class="fal fa-arrow-circle-right"
                    style="font-size: 20px"
                  ></i>
                </ion-avatar>
                <ion-button @click="() => reload()">Restart</ion-button>
              </ion-item>
              <ion-item button lines="full">
                <ion-avatar
                  slot="start"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <i class="fal fa-code-branch" style="font-size: 20px"></i>
                </ion-avatar>
                <ion-label> Version: {{version}}</ion-label>
              </ion-item>
            </ion-list>
          </ion-accordion>
        </ion-accordion-group>
      </ion-list>
    </ion-menu>

    <ion-menu
      @ionWillOpen="() => store.methods.allAgendas()"
      content-id="main"
      menu-id="calendar-menu"
      style="--min-width: 98%"
    >
      <ion-header>
        <ion-toolbar>
          <ion-title>Menu Content</ion-title>
        </ion-toolbar>
      </ion-header>
      <div class="ion-padding" style="height: 100%">
        <div>
          <q-splitter
            v-model="splitterModel"
            style="--width: 100%; height: 100%"
          >
            <template v-slot:before>
              <div class="q-pa-md q-gutter-md" style="height: 100%">
                <q-date
                  mask="DD-MM-YYYY"
                  style="width: 90%"
                  @update:model-value="store.methods.setDate($event)"
                  v-model="store.state.selectedDate"
                  :today-btn="true"
                  event-color="orange"
                >
                  <div class="row items-center justify-end q-gutter-sm">
                    <q-btn
                      label="NEW AGENDA"
                      color="primary"
                      :loading="store.state.loading"
                    >
                      <template v-slot:loading>
                        <q-spinner-hourglass class="on-left" />
                        Loading...
                      </template>
                      <input
                        @change="store.methods.choosePicture($event)"
                        ref="file"
                        type="file"
                        style="
                          opacity: 0;
                          width: 100%;
                          height: 100%;
                          position: absolute;
                        "
                      />
                    </q-btn>
                  </div>
                  <div
                    class="row ion-padding-top items-center justify-end q-gutter-sm"
                  >
                    <q-list bordered separator style="width: 100%">
                      <q-item
                        v-for="agenda in store.methods.agendas"
                        :key="agenda.id"
                        clickable
                        v-ripple
                        @click="store.methods.selectAgenda(agenda)"
                      >
                        <q-item-section>
                          <q-item-label>{{ agenda.location }}</q-item-label>
                          <q-item-label caption
                            >{{ agenda.consultant }}
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </div>
                </q-date>
              </div>
            </template>

            <template v-slot:after>
              <q-list
                class="q-pa-md q-gutter-md rounded-borders"
                style="max-width: 600px; height: 100%"
              >
                <!--                <ion-content-->
                <!--                  class="q-pa-md q-gutter-md"-->
                <!--                  style="width: 100%; min-height: 500px"-->
                <!--                >-->
                <q-item>
                  <q-item-section top>
                    <q-item-label lines="1">
                      <span class="text-weight-medium">Nieuwe Klant </span>
                    </q-item-label>
                  </q-item-section>

                  <q-item-section top side>
                    <div class="text-grey-8 q-gutter-xs">
                      <q-btn
                        class="gt-xs"
                        size="16px"
                        flat
                        round
                        @click="
                          async () => {
                            await customOrder({ name: 'custom' });
                            // insertOrders();
                          }
                        "
                      >
                        <i class="fal fa-camera" style="font-size: 1.5em"></i>
                      </q-btn>
                      <q-btn
                        class="gt-xs"
                        size="16px"
                        flat
                        round
                        @click="
                          async () => {
                            await shoeAdjustment({ name: 'none' });
                          }
                        "
                      >
                        <i class="fal fa-tools" style="font-size: 1.5em"></i>
                      </q-btn>
                    </div>
                  </q-item-section>
                </q-item>

                <q-toolbar class="bg-primary text-white shadow-2">
                  <q-toolbar-title>Agenda's</q-toolbar-title>
                </q-toolbar>

                <q-list
                  v-if="store.state.selectedAgenda.length === 0"
                  separator
                >
                  <ion-content
                    class="q-pa-md"
                    style="width: 100%; min-height: 65vh"
                  >
                    <template
                      v-for="(agenda, index) in store.state.all_agendas"
                      :key="agenda.id"
                    >
                      <q-banner
                        dense
                        v-if="
                          agenda.date !==
                          store.state.all_agendas[index - 1]?.date
                        "
                        class="bg-blue-grey-8 text-white"
                      >
                        {{ agenda.date }}
                      </q-banner>

                      <q-separator></q-separator>
                      <q-item
                        clickable
                        v-ripple
                        @click="store.methods.selectAgenda(agenda)"
                      >
                        <q-item-section>
                          <!--                          <q-item-label>{{ agenda.date }}</q-item-label>-->
                          <q-item-label>{{ agenda.location }}</q-item-label>
                          <q-item-label caption
                            >{{ agenda.consultant }}
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                    </template>
                  </ion-content>
                </q-list>

                <q-list>
                  <ion-content
                    class="q-pa-md q-gutter-md"
                    style="width: 100%; height: 65vh"
                  >
                    <template v-if="store.state.selectedAgenda.length !== 0">
                      <div class="text-grey-8 q-gutter-xs">
                        <q-btn
                          class="gt-xs"
                          size="16px"
                          round
                          @click="
                            async () => {
                              store.methods.showAgendas();
                            }
                          "
                        >
                          <i
                            class="fal fa-arrow-left"
                            style="font-size: 1.5em"
                          ></i>
                        </q-btn>
                      </div>
                      <template
                        v-for="item in store.state.selectedAgenda.appointments"
                        :key="item"
                      >
                        <q-separator></q-separator>
                        <q-item dense>
                          <q-item-section top style="width: 50%">
                            <q-item-label lines="1">
                              <span class="text-weight-medium">Klant: </span>
                              <span class="text-grey-8">{{ item.name }}</span>
                            </q-item-label>
                            <q-item-label lines="1">
                              <span class="text-weight-medium"
                                >Leestnummer:
                              </span>
                              <span class="text-grey-8">{{ item.number }}</span>
                            </q-item-label>
                          </q-item-section>

                          <q-item-section top side style="width: 50%">
                            <div class="text-grey-8 q-gutter-xs">
                              <q-btn
                                class="gt-xs"
                                size="16px"
                                flat
                                round
                                @click="
                                  async () => {
                                    await customOrder(item);
                                  }
                                "
                              >
                                <i
                                  class="fal fa-camera"
                                  style="font-size: 1.5em"
                                ></i>
                              </q-btn>
                              <q-btn
                                class="gt-xs"
                                size="16px"
                                flat
                                round
                                @click="
                                  async () => {
                                    await shoeAdjustment(item);
                                  }
                                "
                              >
                                <i
                                  class="fal fa-tools"
                                  style="font-size: 1.5em"
                                ></i>
                              </q-btn>
                              <q-btn
                                size="16px"
                                flat
                                round
                                @click="item.show_input = !item.show_input"
                              >
                                <i
                                  class="fal fa-timeline-arrow"
                                  style="font-size: 1.5em"
                                ></i>
                              </q-btn>
                              <q-item-label
                                lines="2"
                                v-if="item.planning !== ''"
                              >
                                <ion-chip>
                                  <ion-label>{{ item.planning }}</ion-label>
                                </ion-chip>
                              </q-item-label>
                              <ion-item v-if="item.show_input">
                                <ion-input
                                  @change="
                                    store.state.planning = $event.target.value
                                  "
                                  label="planning: "
                                ></ion-input>
                                <ion-button
                                  @click="store.methods.submitPlanning(item)"
                                  shape="round"
                                  >Plan
                                </ion-button>
                              </ion-item>
                            </div>
                          </q-item-section>
                        </q-item>
                      </template>
                    </template>
                  </ion-content>
                </q-list>
              </q-list>
            </template>
          </q-splitter>
        </div>
      </div>
    </ion-menu>
    <ion-router-outlet id="main" ref="outlet"></ion-router-outlet>
  </ion-app>
</template>
<script>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
// import "./theme/auth-style.css";

import {
  IonText,
  IonAccordion,
  IonAccordionGroup,
  IonRefresherContent,
  IonTitle,
  IonRefresher,
  IonApp,
  IonGrid,
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonChip,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonItem,
  IonInput,
  IonLabel,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  menuController,
  useIonRouter,
} from "@ionic/vue";

import { defineComponent, provide, ref } from "vue";
import { languageOutline, navigate } from "ionicons/icons";
import store from "@/store/index.js";
import { QSeparator, QSkeleton, QSplitter, QDate, QList,QToolbar,QSpinnerHourglass,QBtn,QItemSection,QItem,QItemLabel,QToolbarTitle,QBanner } from "quasar";
// import "@/locales/AWSTranslations.ts";
import { fetchUserAttributes } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { Toast } from "@capacitor/toast";
import { version } from "../package.json";
export default defineComponent({
  name: "App",
  methods: {
    navigate() {
      return navigate;
    },
  },
  components: {
    IonText,
    Authenticator,
    IonAccordionGroup,
    IonChip,
    IonAccordion,
    IonItem,
    IonLabel,
    IonAvatar,
    IonTitle,
    IonButtons,
    IonGrid,
    IonRouterOutlet,
    IonApp,
    IonRefresherContent,
    IonRefresher,
    IonCard,
    IonInput,
    IonCardContent,
    IonSearchbar,
    IonContent,
    IonMenu,
    IonToolbar,
    IonHeader,
    IonButton,
    IonCardTitle,
    IonCardSubtitle,
    IonCardHeader,
    IonCol,
    IonRow,
    IonSegmentButton,
    IonSegment,
    IonBadge,
    IonList,
    QSeparator,
    QSkeleton,
    QSplitter,
    QDate,
    QList,QToolbar,QSpinnerHourglass,QBtn,QItemSection,QItem,QItemLabel,QToolbarTitle,QBanner
  },
  setup() {
    const orderListType = ref("history");
    const disabled = ref();
    const router = useIonRouter();

    const scroller = ref(null);
    const user = ref();
    const logged_in_user = ref();
    const loading = ref(true);

    function scrollMenuContent() {
      console.log(scroller.value.$el.scrollToPoint);
      scroller.value.$el.scrollToPoint(500);
    }

    function navigateTo(order, location) {
      menuController.close();
      router.push(location + order.id);
    }

    function setOrderListType(value) {
      orderListType.value = value;
    }

    const accordionGroup = ref();

    function toDate(timestamp) {
      const date = new Date(timestamp);
      const currentDate =
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "-" +
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "-" +
        date.getFullYear() +
        " " +
        (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) +
        ":" +
        (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes());
      return currentDate;
    }

    provide("store", store);

    const scrollRef = ref(false);

    function onScrollFirst(position) {
      if (position.detail.currentY > 90) {
        scrollRef.value = true;
      } else if (position.detail.currentY < 90) {
        scrollRef.value = false;
      }
    }

    const editButton = ref(false);
    const date = new Date(Date.now());
    const currentDate = ref(
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "-" +
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "-" +
        date.getFullYear() +
        " " +
        (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) +
        ":" +
        (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes())
    );


    function timeDiffCalc(dateFuture, dateNow) {
      let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
      const days = Math.floor(diffInMilliSeconds / 86400);
      diffInMilliSeconds -= days * 86400;
      const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
      diffInMilliSeconds -= hours * 3600;
      const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
      diffInMilliSeconds -= minutes * 60;
      let dagen = 0;
      let dayhours = 0;

      if (days > 0) {
        dayhours = days * 24;
      }

      dagen = hours + dayhours;

      return dagen;
    }

    const doRefresh = async (event) => {
      loading.value = false;
      console.log("Begin async operation");
      await store.methods.getSubmittedOrders();
      await store.methods.getModificationOrders();
      await store.methods.getArchivedOrders();
      setTimeout(() => {
        event.target.complete();
        console.log("Async operation has ended");
        loading.value = true;
      }, 1000);
    };

    function reload() {
      window.location.reload();
    }

    function dateCompare(d1, d2) {
      if (timeDiffCalc(new Date(new Date(d2)), Date.now()) < 3) {
        editButton.value = true;
      } else {
        editButton.value = false;
      }
    }


    disabled.value = true;
    orderListType.value = "history";

    (async () => {
      try {
        user.value = await fetchUserAttributes();
        logged_in_user.value = user.value;
        store.state.signedIn = true;
        await store.methods.allAgendas();
        if (store.state.submittedOrders.length === 0) {
          await getOrders();
        }
        console.log("User is signed in!");
      } catch (error) {
        console.log("user is not logged in", error);
      }
    })();

    async function getOrders() {
      if (store.state.signedIn === false) {
        return;
      }
      // loading.value = false;
      await store.methods.getSubmittedOrders();
      await store.methods.getArchivedOrders();
      await store.methods.getModificationOrders();
      // loading.value = true;
    }

    const closeAccordion = () => {
      if (accordionGroup.value) {
        accordionGroup.value.$el.value = undefined;
      }
    };

    const customOrder = async (v) => {
      await menuController.close("calendar-menu");
      setTimeout(() => {
        if (JSON.stringify(v).includes("custom")) {
          router.push("/order/" + JSON.stringify(v));
        } else {
          router.push("/order/custom/" + JSON.stringify(v));
        }
      }, 500);
    };

    async function shoeAdjustment(item) {
      console.log(item);
      await menuController.close("calendar-menu");
      router.push("/custom-shoe-adjustment/" + JSON.stringify(item));
    }

    Hub.listen("auth", async ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          user.value = await fetchUserAttributes();
          logged_in_user.value = user.value;
          store.state.signedIn = true;
          store.state.logged_in_user = user.value;
          await Toast.show({
            text: "Je bent ingelogd!",
            position: "center",
          });
          await store.methods.allAgendas();
          closeAccordion();
          console.log("user have been signedIn successfully.");
          await getOrders(user);
          break;
        case "signedOut":
          user.value = undefined;
          logged_in_user.value = undefined;
          store.state.logged_in_user = "";
          store.state.signedIn = false;

          await Toast.show({
            text: "Je bent uitgelogd!",
            position: "center",
          });
          console.log("user have been signedOut successfully.");
          break;
        case "tokenRefresh":
          console.log("auth tokens have been refreshed.");
          break;
        case "tokenRefresh_failure":
          console.log("failure while refreshing auth tokens.");
          break;
      }
    });

    // store.methods.allAgendas();

    function reorder(orderId) {
      menuController.close("FtrSideMenu").then(() => {
        router.push("/order/reorder_" + orderId);
      });
    }

    const models = [];

    async function insertOrders() {
      for (let i = 0; i < models.length; i++) {
        const model = models[i];
        // console.log(model.formData)
        const formData = JSON.parse(model.formData.replaceAll("'", '"'));
        const filename = JSON.parse(model.filename.replaceAll("'", '"'));

        // const formData = model.formData
        //  console.log(model.id)
        // console.log(JSON.parse(filename))
        // let f = JSON.parse(model.formData);

        const newOrder = {
          modelnumber: model?.modelnumber.toString() || "na",
          clientID: model.clientID.toString(),
          organisation: model.organisation,
          userName: model.userName,
          orderIdOne: model.orderIdOne.toString(),
          orderIdTwo: model.orderIdTwo.toString(),
          filename: [],
          exportedAt: null,
          // gender: gender,
          status: model.status,
          search: model.search,
          submittedAt: model.submittedAt,
          formData: formData,
        };
        for (let i = 0; i < filename.length; i++) {
          newOrder.filename.push({ filename: filename[i].filename });
        }
        // console.log(newOrder);
        // console.log(model);
        // const saveResult = await DataStore.save(new CareOrder(newOrder));
        // console.log(saveResult);
      }
    }

    return {
      insertOrders,
      splitterModel: ref(40),
      user,
      reorder,
      accordionGroup,
      closeAccordion,
      logged_in_user,
      editButton,
      setOrderListType,
      version,
      onScrollFirst,
      orderListType,
      disabled,
      doRefresh,
      navigateTo,
      dateCompare,
      menuController,
      store,
      languageOutline,
      router,
      currentDate,
      loading,
      getOrders,
      reload,
      scrollRef,
      customOrder,
      shoeAdjustment,
      toDate,
      scroller,
      scrollMenuContent,
    };
  },
  data() {
    return {
      signUpFields: [
        {
          type: "email",
          inputProps: { required: true, autocomplete: "username" },
        },
        {
          type: "password",
          inputProps: { required: true, autocomplete: "new-password" },
        },
        {
          type: "phone_number",
        },
      ],
      showOrderMenu: ref(),
    };
  },
});
</script>

<style>
.q-splitter__panel .q-splitter__after {
  height: 500px;
}

.amplify-field-group :not(:first-child) .amplify-input {
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
}

[data-amplify-authenticator] [data-amplify-container] {
  position: relative;
  place-self: center;
  width: 25rem !important;
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
}

.amplify-field-group :not(:first-child) .amplify-input {
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
}

[data-amplify-authenticator] [data-amplify-container] {
  --width: var(--amplify-components-authenticator-container-width-max);
}

[data-amplify-authenticator] {
  --amplify-components-authenticator-container-width-max: 100%;
  --amplify-colors-background-primary: var(--ion-color-tertiary);
  --amplify-colors-background-secondary: var(--amplify-colors-neutral-60);
  --amplify-colors-background-tertiary: var(--amplify-colors-neutral-60);
  --amplify-colors-border-primary: transparent;
  --amplify-colors-border-secondary: transparent;
  --amplify-colors-border-tertiary: transparent;
  --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
  --amplify-colors-brand-primary-20: var(--amplify-colors-teal-90);
  --amplify-colors-brand-primary-40: var(--amplify-colors-teal-80);
  --amplify-colors-brand-primary-60: var(--amplify-colors-teal-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
  --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
  --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
  --amplify-colors-font-interactive: var(--amplify-colors-teal-80);
  --amplify-components-heading-color: var(--amplify-colors-neutral-20);
  --amplify-components-tabs-item-active-border-color: var(--ion-color-primary);
  --amplify-components-tabs-item-active-color: var(--ion-color-primary);
  --amplify-components-tabs-item-color: var(--ion-color-primary);
  --amplify-components-tabs-item-focus-color: var(--ion-color-primary);
  --amplify-components-text-color: var(--amplify-colors-font-interactive);
}

[data-amplify-authenticator] [data-state="inactive"] {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-primary-tint);
  opacity: 0.5;
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  background-color: transparent;
}
</style>

<style scoped>
*,
*:before,
*:after {
  box-sizing: border-box !important;
}

ion-menu {
  --width: 100%;
}

ion-menu ion-content {
  --width: 100%;
}

ion-menu ion-card {
  width: 90%;
  margin-left: 5%;
}

ion-menu ion-card-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

ion-menu ion-content ion-content {
  padding-top: 0;
}

ion-list {
  background: var(--ion-color-tertiary);
}

ion-toolbar {
  --background: var(--ion-color-secondary);
}

ion-accordion ion-item[slot="header"],
ion-accordion ion-item[slot="header"] {
  --color: var(--ion-color-primary);
  --background: var(--ion-color-tertiary);
}

ion-accordion {
  margin: 0 auto;
}

ion-accordion.accordion-expanding,
ion-accordion.accordion-expanded {
  width: calc(90% + 6%);
  margin: 2% auto;
}

@media (min-width: 350px) {
}

/*###############################################################*/
@media (min-width: 576px) {
}

/*###############################################################*/
@media (min-width: 768px) {
  ion-menu {
    --width: 29rem;
  }

  ion-menu ion-content {
    --width: 29rem;
  }
}

/*###############################################################*/
@media (min-width: 992px) {
}

/*###############################################################*/
@media (min-width: 1024px) {
}

/*###############################################################*/
@media (min-width: 1150px) {
}
</style>
